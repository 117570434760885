
import TheUpgradePlanButton from './TheUpgradePlanButton.vue'
import TheNotificationsDropdown from './TheNotificationsDropdown.vue'

export default {
  name: 'TheNavbar',
  components: {
    TheUpgradePlanButton,
    TheNotificationsDropdown
  }
}
