
import { mapState } from 'vuex'
import formsMixin from '~/lib/mixins/forms.mixin'

const MAX_AVATARS = 5

export default {
  name: 'TheUserAvatarList',
  mixins: [formsMixin],

  props: {
    workspaceName: {
      type: String,
      required: true
    },
    workspaceOwnerId: {
      type: String,
      required: true
    }
  },

  async fetch () {
    if (this.workspaceId) {
      this.users = await this.$api.users.find(this.workspaceId)
      // this.invitations = await this.$api.invitations.find(this.workspaceId)
    }
  },

  data () {
    return {
      formSchema: [
        {
          component: 'div',
          class: 'flex flex-col gap-4',
          children: [
            {
              type: 'text',
              name: 'email',
              'validation-name': 'Email',
              label: this.$t('components.layout.theUserAvatarList.label'),
              placeholder: this.$t('components.layout.theUserAvatarList.placeholder'),
              validation: 'email',
              'input-class': 'w-full'
            }
          ]
        }
      ],
      model: {
        email: ''
      },
      invitePopoverShown: false,
      users: []
      // invitationList: [],
      // invitationSuggestions: ['Jorge', 'Euclides', 'Bernadete'],
      // pendingInvitations: [{ email: 'fulano@detal.com', id: '123', sentAt: '2023-01-18 13:44:32' }, { email: 'seclano@detal.com', id: '1234', sentAt: '2023-01-18 13:44:32' }]
    }
  },

  computed: {
    ...mapState('auth', ['user']),
    workspaceId () {
      return this.$route.params.workspaceid
    }
  },

  watch: {
    workspaceId: '$fetch',
    '$route.query': '$fetch'
  },

  methods: {
    getFirstUsers () {
      return this.users.slice(0, MAX_AVATARS)
    },

    totalHiddenUsers () {
      const total = this.users.length - MAX_AVATARS

      return total > 0
        ? total
        : 0
    },

    removeUserFromWorkspace ({ id, name }) {
      this.$msgbox.confirm(this, {
        title: this.$t('components.layout.theUserAvatarList.title'),
        message: `${name} ${this.$t('components.layout.theUserAvatarList.message')}`,
        noButtonText: this.$t('components.layout.theUserAvatarList.noButtonText'),
        yesButtonText: this.$t('components.layout.theUserAvatarList.yesButtonText')
      }, async () => {
        try {
          await this.$api.workspaces.deleteUser(this.workspaceId, id)
          this.$notification.success(this, this.$t('components.layout.theUserAvatarList.userRemoved'))
          this.$fetch()
        } catch (error) {
          this.handleError(error)
        }
      })
    },

    async sendInvitation (data) {
      try {
        await this.$api.workspaces.invite(this.workspaceId, data.email)
        this.invitePopoverShown = false
        this.$formulate.reset('inviteForm')
        this.$notification.success(this, this.$t('components.layout.theUserAvatarList.invitationSent'))
      } catch (error) {
        this.handleError(error)
      }
    },

    getInvitationsSuggestions () {
      return ['Fulano', 'Ciclano', 'Beltrano']
    },

    revocable (userId) {
      return this.user.id !== userId && this.workspaceOwnerId !== userId
    }
  }
}
