import Pusher from 'pusher-js'

export default {
  data () {
    return {
      notificationPusher: null,
      reconnectAttempts: 0,
      maxReconnectAttempts: 5
    }
  },

  computed: {
    notificationChannel () {
      if (!this.$auth.user) {
        return null
      }
      return `User.${this.$auth.user.id}`
    }
  },

  async mounted () {
    await this.updateUnreadCount()
    this.initializePusher()
    window.addEventListener('online', this.handleOnline)
  },

  beforeDestroy () {
    if (this.notificationPusher) {
      this.notificationPusher.disconnect()
    }
    window.removeEventListener('online', this.handleOnline)
  },

  methods: {
    handleOnline () {
      this.reconnectPusher()
    },

    async reconnectPusher () {
      if (this.reconnectAttempts >= this.maxReconnectAttempts) {
        return
      }

      this.reconnectAttempts++

      if (this.notificationPusher) {
        this.notificationPusher.disconnect()
        this.notificationPusher = null
      }

      await this.initializePusher()
    },

    async updateUnreadCount () {
      try {
        const count = await this.$notificationsService.getUnreadCount()
        this.$store.commit('notifications/setUnreadCount', count)
      } catch (error) {}
    },

    initializePusher () {
      if (!this.$auth.user) {
        return
      }

      if (this.notificationPusher) {
        return
      }

      try {
        this.notificationPusher = new Pusher(process.env.PUSHER_APP_KEY, {
          cluster: process.env.PUSHER_APP_CLUSTER,
          debug: true
        })

        this.notificationPusher.connection.bind('connecting', () => {})

        this.notificationPusher.connection.bind('connected', () => {
          this.reconnectAttempts = 0
        })

        this.notificationPusher.connection.bind('failed', () => {
          setTimeout(() => this.reconnectPusher(), 5000)
        })

        this.notificationPusher.connection.bind('disconnected', () => {
          setTimeout(() => this.reconnectPusher(), 5000)
        })

        this.notificationPusher.connection.bind('error', () => {})

        const channel = this.notificationPusher.subscribe(this.notificationChannel)

        channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', async (notification) => {
          try {
            await this.updateUnreadCount()
            if (Notification.permission === 'granted') {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const browserNotification = new Notification(notification.data?.subject || 'Nova notificação', {
                body: notification.data?.text || 'Você tem uma nova notificação',
                icon: '/icon.png'
              })
            }
          } catch (error) {}
        })

        channel.bind('BroadcastNotificationCreated', async (notification) => {
          try {
            await this.updateUnreadCount()
            if (Notification.permission === 'granted') {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const browserNotification = new Notification(notification.data?.subject || 'Nova notificação', {
                body: notification.data?.text || 'Você tem uma nova notificação',
                icon: '/icon.png'
              })
            }
          } catch (error) {}
        })
      } catch (error) {}
    }
  }
}
