
import { mapState } from 'vuex'
import notificationsMixin from '~/lib/mixins/notifications.mixin'

export default {
  name: 'TheNotificationsDropdown',

  mixins: [notificationsMixin],

  data () {
    return {
      notifications: [],
      loading: false,
      error: null,
      hasMore: true,
      page: 1,
      isClosing: false,
      isActive: false
    }
  },

  computed: {
    ...mapState({
      unreadCount: state => state.notifications.unreadCount
    })
  },

  created () {
    this.$store.dispatch('notifications/fetchUnreadCount')
  },

  methods: {
    async fetchNotifications (reset = false) {
      if (this.loading || (!this.hasMore && !reset)) {
        return
      }

      this.loading = true
      this.error = null

      if (reset) {
        this.notifications = []
        this.page = 1
        this.hasMore = true
      }

      try {
        const response = await this.$api.notifications.find({ page: this.page })
        this.notifications.push(...response.data)
        this.hasMore = response.data.length > 0
        this.page++
      } catch (error) {
        this.error = error.message
      } finally {
        this.loading = false
      }
    },

    handleScroll (event) {
      const container = event.target
      const { scrollTop, scrollHeight, clientHeight } = container

      if (scrollTop + clientHeight >= scrollHeight - 100 && !this.loading && this.hasMore) {
        this.fetchNotifications()
      }
    },

    async toggleRead (notification) {
      try {
        if (notification.readAt) {
          await this.$api.notifications.markAsUnread(notification.id)
          notification.readAt = null

          await this.$store.dispatch('notifications/fetchUnreadCount')
        } else {
          await this.$api.notifications.markAsRead(notification.id)
          notification.readAt = new Date()

          await this.$store.dispatch('notifications/fetchUnreadCount')
        }
      } catch (error) {
        console.error('Error toggling notification:', error)
      }
    },

    async markAllAsRead () {
      try {
        await this.$api.notifications.markAllAsRead()

        this.notifications.forEach((notification) => {
          notification.readAt = new Date().toISOString()
        })

        await this.$store.dispatch('notifications/fetchUnreadCount')
      } catch (error) {
        console.error('Error marking all notifications as read:', error)
      }
    },

    handleNotificationClick (notification, event) {
      if (!notification.data.link) {
        return
      }

      event.preventDefault()
      window.location.href = notification.data.link
    },

    getNotificationIcon (notification) {
      const type = notification.data.type || 'info'
      const icons = {
        success: 'oi-check-circle-1',
        error: 'oi-close-circle',
        warning: 'oi-alert-circle',
        info: 'oi-info-circle'
      }
      return icons[type] || icons.info
    },

    getNotificationIconClass (notification) {
      const type = notification.data.type || 'info'
      const classes = {
        success: 'text-green-500',
        error: 'text-red-500',
        warning: 'text-yellow-500',
        info: 'text-blue-500'
      }
      return classes[type] || classes.info
    },

    formatDate (date) {
      if (!date) {
        return ''
      }
      const now = this.$dayjs()
      const target = this.$dayjs(date)
      const diffMinutes = now.diff(target, 'minute')
      const diffHours = now.diff(target, 'hour')
      const diffDays = now.diff(target, 'day')
      const diffMonths = now.diff(target, 'month')
      const diffYears = now.diff(target, 'year')

      if (diffMinutes < 1) {
        return this.$t('common.date.justNow')
      }
      if (diffMinutes === 1) {
        return this.$t('common.date.minuteAgo')
      }
      if (diffMinutes < 60) {
        return this.$t('common.date.minutesAgo', { n: diffMinutes })
      }
      if (diffHours === 1) {
        return this.$t('common.date.hourAgo')
      }
      if (diffHours < 24) {
        return this.$t('common.date.hoursAgo', { n: diffHours })
      }
      if (diffDays === 1) {
        return this.$t('common.date.yesterday')
      }
      if (diffDays < 30) {
        return this.$t('common.date.daysAgo', { n: diffDays })
      }
      if (diffMonths === 1) {
        return this.$t('common.date.monthAgo')
      }
      if (diffMonths < 12) {
        return this.$t('common.date.monthsAgo', { n: diffMonths })
      }
      if (diffYears === 1) {
        return this.$t('common.date.yearAgo')
      }
      return this.$t('common.date.yearsAgo', { n: diffYears })
    },

    toggleDropdown (active) {
      this.isActive = active
      if (active) {
        this.isClosing = false

        this.$store.dispatch('notifications/fetchUnreadCount')

        this.fetchNotifications(true)
      } else {
        setTimeout(() => {
          this.isClosing = false
        }, 300)
        this.isClosing = true
      }
    }
  }
}
