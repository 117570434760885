import { render, staticRenderFns } from "./TheUserAvatarList.vue?vue&type=template&id=5e839080&"
import script from "./TheUserAvatarList.vue?vue&type=script&lang=js&"
export * from "./TheUserAvatarList.vue?vue&type=script&lang=js&"
import style0 from "./TheUserAvatarList.vue?vue&type=style&index=0&id=5e839080&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack-edge/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserAvatar: require('/workspace/components/common/UserAvatar.vue').default,UserMiniProfile: require('/workspace/components/common/UserMiniProfile.vue').default})
