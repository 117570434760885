import useRepository from './repository/apiclient-axios.repository'

export default function useNotificationsService ({ $axios }) {
  const repository = useRepository({ $axios })

  /**
   * Returns all notifications for the authenticated user.
   *
   * @param {Object} params - Query parameters
   * @param {Number} params.page - Page number
   * @param {Number} params.limit - Number of items per page
   * @param {Boolean} params.read - Filter by read status
   * @returns {Promise<Array>} Returns an array of notifications.
   */
  async function find (params = {}) {
    const response = await repository.find('notifications', params)
    return response
  }

  /**
   * Returns the count of unread notifications for the authenticated user.
   *
   * @returns {Promise<Number>} Returns the count of unread notifications.
   */
  async function getUnreadCount () {
    try {
      const response = await repository.find('notifications')
      return response.meta?.unread_count || 0
    } catch (error) {
      console.error('Error getting unread count:', error)
      return 0
    }
  }

  /**
   * Marks all notifications as read.
   *
   * @returns {Promise<Object>} Returns the response from the API.
   */
  async function markAllAsRead () {
    const response = await $axios.put('notifications', { read: true })
    return response
  }

  /**
   * Marks a notification as read.
   *
   * @param {String} notificationId - ID of the notification to mark as read
   * @returns {Promise<Object>} Returns the updated notification.
   */
  async function markAsRead (notificationId) {
    const response = await $axios.put(`notifications/${notificationId}`, { read: true })
    return response
  }

  /**
   * Marks a notification as unread.
   *
   * @param {String} notificationId - ID of the notification to mark as unread
   * @returns {Promise<Object>} Returns the updated notification.
   */
  async function markAsUnread (notificationId) {
    const response = await $axios.put(`notifications/${notificationId}`, { read: false })
    return response
  }

  return {
    find,
    getUnreadCount,
    markAllAsRead,
    markAsRead,
    markAsUnread
  }
}
