export const state = () => ({
  unreadCount: 0
})

export const mutations = {
  incrementUnreadCount (state) {
    state.unreadCount++
  },

  setUnreadCount (state, count) {
    state.unreadCount = count
  }
}

export const actions = {
  async fetchUnreadCount ({ commit }) {
    try {
      const count = await this.$notificationsService.getUnreadCount()
      commit('setUnreadCount', count)
    } catch (error) {
    }
  }
}
